export default class ECalendar {
  constructor( element ) {
    element.classList.add('javascript');

    let categories = Array.from(document.querySelectorAll('.calendar-category'));
    let calendarEvents = Array.from(document.querySelectorAll('.event-container'));
    let datePickers = Array.from(document.querySelectorAll('.event-date__picker'));
    let startDate = document.querySelector('#date-picker__start');
    let endDate = document.querySelector('#date-picker__end');
    let showAll = document.querySelector('.event-show__all');
    let views = Array.from(document.querySelectorAll('.calendar-view__item'));
    let currentMonth = document.querySelector('.event-month');
    let calendarDays = document.querySelector('.calendar-days');
    let changeCourse = document.getElementById('change_course');
    let participants = Array.from(document.querySelectorAll('.participant'));
    let price = document.querySelector('.calendar-price');
    let inputTime = document.querySelector('.input-course__time');
    let signInConditions = document.querySelector('.signin-conditions');
    let courseServices = document.querySelector('.price-includes');
    let today = new Date();
    let date = new Date();

    currentMonth.textContent = date.toLocaleDateString('en-EN', {month:'long', year:'numeric'});
    today.setHours(0,0,0,0);
    renderCalendar();
    monthColor();
    function renderCalendar() {
      const prevLastDay = new Date(date.getFullYear(),date.getMonth(),0).getDate();
      const totalMonthDay = new Date(date.getFullYear(),date.getMonth()+1,0).getDate();
      const startWeekDay = new Date(date.getFullYear(),date.getMonth(),1).getDay() - 2;
      calendarDays.innerHTML = '';
      let totalCalendarDay = 6 * 7;
      for (let i = 0; i < totalCalendarDay; i++) {
        let day = i-startWeekDay;
        if(i <= startWeekDay){
          // adding previous month days
          calendarDays.innerHTML += `<div class='padding-day'><p>${prevLastDay+day}</p><div class='month-content'></div></div>`;
        }else if(i <= startWeekDay+totalMonthDay){
          let cellDate = date.getFullYear() + '-' + ((date.getMonth()+1).toString().length == 1 ? '0'+(date.getMonth()+1) : (date.getMonth()+1)) + '-' + (day.toString().length == 1 ? `0${day}` : day);
          // adding this month days
          date.setDate(day);
          date.setHours(0,0,0,0);
          let dayClass = date.getTime()===today.getTime() ? 'current-day' : 'month-day';
          let content = window.eventDescription[cellDate] || '';
          calendarDays.innerHTML += '<div class="' + dayClass + '"><p>' + day + '</p><hr><div class="month-inner">' + '<div class="month-content">' + content + '</div>' + '</div></div>';

        }else{
          // adding next month days
          calendarDays.innerHTML += `<div class='padding-day'><p>${day-totalMonthDay}</p><div class='month-content'></div></div>`;

        }
      }
    }

    document.querySelectorAll('.soft-btn').forEach(function (element) {
      element.addEventListener('click', function () {
        date = new Date(currentMonth.textContent);
        date.setMonth(date.getMonth() + (element.classList.contains('prev') ? -1 : 1));
        currentMonth.textContent = date.toLocaleDateString('en-EN', {month:'long', year:'numeric'});
        renderCalendar();
        monthColor();
      });});

    function monthColor() {
      let monthContents = Array.from(document.querySelectorAll('.month-content'));
      monthContents.forEach(monthContent =>{
        if (monthContent.textContent.toLowerCase().includes('tesvolt')){
          monthContent.classList.add('tesvolt-color');
        } else if(monthContent.textContent.toLowerCase().includes('batterx')){
          monthContent.classList.add('batterx-color');
        } else if(monthContent.textContent.toLowerCase().includes('fenecon')){
          monthContent.classList.add('fenecon-color');
        } else if(monthContent.textContent.toLowerCase().includes('produkt')){
          monthContent.classList.add('product-color');
        }
      });
    }

    function handleChangeView(event){
      views.forEach(view =>{
        view.classList.remove('active-view');
      });
      event.currentTarget.classList.add('active-view');
      if (event.currentTarget.classList.contains('agenda-button')){
        document.querySelector('.allevents-agenda').classList.remove('active-view__hidden');
        document.querySelector('.allevents-month').classList.add('active-view__hidden');
        document.querySelector('.event-date').classList.remove('active-view__hidden');
        document.querySelector('.event-month__picker').classList.add('active-view__hidden');
      } else {
        document.querySelector('.allevents-agenda').classList.add('active-view__hidden');
        document.querySelector('.allevents-month').classList.remove('active-view__hidden');
        document.querySelector('.event-date').classList.add('active-view__hidden');
        document.querySelector('.event-month__picker').classList.remove('active-view__hidden');
      }
    }

    function handleClickCategory(event){
      categories.forEach(category =>{
        if (category != event.currentTarget) {
          category.classList.remove('active-category');
        }
      });


      if (!(event.currentTarget.classList.contains('active-category'))){
        event.currentTarget.classList.add('active-category');
        calendarEvents.forEach((singleevent) => {
          let activecategory = event.currentTarget.getAttribute('data-category').toUpperCase();
          let eventcategory = singleevent.className.split(' ')[1].toUpperCase();
          if (!(activecategory.indexOf(eventcategory) > -1)) {
            singleevent.classList.add('active-category__hidden');
          } else {
            singleevent.classList.remove('active-category__hidden');
          }
        });
      } else {
        event.currentTarget.classList.remove('active-category');
        calendarEvents.forEach((singleevent) => {
          singleevent.classList.remove('active-category__hidden');
        });
      }
    }

    function handleChangeDate(){
      calendarEvents.forEach((singleevent) => {
        let eventDate = singleevent.querySelector('.event-date');
        singleevent.classList.remove('active-date__hidden');
        if (!((new Date(startDate.value).getTime() <= new Date(eventDate.innerHTML).getTime()) && (new Date(eventDate.innerHTML).getTime() <= new Date(endDate.value).getTime()))){
          singleevent.classList.add('active-date__hidden');
        }
      });
    }

    function handleShowAll(){
      calendarEvents.forEach((singleevent) => {
        singleevent.classList.remove('active-date__hidden');
        singleevent.classList.remove('active-category__hidden');
      });
      categories.forEach(category =>{
        category.classList.remove('active-category');
      });
    }

    function handleChangeCourse(){
      console.log(changeCourse.selectedOptions[0].value.includes('Produkt'));
      if (changeCourse.selectedOptions[0].value.includes('Produkt')){
        participants.forEach((participant) => {
          participant.classList.remove('calendar-hidden');
        });
        document.querySelector('.price-includes').innerHTML = '';
        courseServices.innerHTML = 'Sie erhalten von uns Schulungsunterlagen in elektronischer Form, Getränke und Verpflegung während der Schulung.';
        signInConditions.innerHTML = '8 Tage vor Schulungsdatum.&#10;WICHTIG: Kostenfreie Stornierungen von gebuchten Plätzen sind nur bis 7 Tage vor Veranstaltungsdatum möglich.';
      } else {
        participants.forEach((participant) => {
          participant.classList.add('calendar-hidden');
        });
        courseServices.innerHTML = 'Die Teilnehmergebühr beinhaltet die Schulungsunterlagen in elektronischer Form, Getränke und Verpflegung während der Schulung.';
        signInConditions.innerHTML = '8 Tage vor Schulungsdatum.&#10;WICHTIG: Kostenfreie Stornierungen von gebuchten Plätzen sind nur bis 7 Tage vor Veranstaltungsdatum möglich. Bei späteren Stornierungen müssen wir die volle Teilnehmergebühr in Rechnung stellen.';
      }
      if ((changeCourse.selectedOptions[0].value.includes('Fenecon')) || changeCourse.selectedOptions[0].value.includes('batterX')){
        price.innerHTML = 'Die Teilnehmergebühr beträgt <strong>EUR 99,-</strong> netto (zzgl. MwSt.)';
        inputTime.value = '10.00 bis 16.00 Uhr';
      } else if ((changeCourse.selectedOptions[0].value.includes('erster')) || changeCourse.selectedOptions[0].value.includes('zweiter')){
        price.innerHTML = 'Die Teilnehmergebühr beträgt <strong>EUR 175,-</strong> netto (zzgl. MwSt.)';
        inputTime.value = '10.00 bis 17.00 Uhr';
      } else if (changeCourse.selectedOptions[0].value.includes('beide')){
        price.innerHTML = 'Die Teilnehmergebühr beträgt <strong>EUR 280,-</strong> netto (zzgl. MwSt.)';
        inputTime.value = '10.00 bis 17.00 Uhr';
      } else if (changeCourse.selectedOptions[0].value.includes('Produkt')) {
        price.innerHTML = 'Die Teilnahme ist kostenlos!';
        inputTime.value = '10.00 bis 17.00 Uhr';
      } else {
        price.innerHTML = '';
        inputTime.value = '';
      }

    }

    categories.forEach(category => {
      category.addEventListener('click', handleClickCategory);
    });

    datePickers.forEach(datePicker =>{
      datePicker.addEventListener('change', handleChangeDate);
    });

    views.forEach(view =>{
      view.addEventListener('click', handleChangeView);
    });

    showAll.addEventListener('click', handleShowAll);

    changeCourse.addEventListener('change', handleChangeCourse);

  }
}
