// import './map.css';

export default class Map {
  constructor( element ) {
    element.classList.add('javascript');

    // eslint-disable-next-line no-undef
    if (!window.hasOwnPropert.call('GOOGLE_MAPS_API_KEY') || !GOOGLE_MAPS_API_KEY) {
      return;
    }

    let script = document.createElement('script');
    // eslint-disable-next-line no-undef
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&language=${element.dataset.language}&callback=initMap`;
    script.defer = true;

    let map, geoCoder;

    const options = {
      center: {
        lat: 48.635851,
        lng: 11.777602,
      },
      zoom: 15,
    };

    const address = `${element.dataset.street} ${element.dataset.number} ${element.dataset.zip} ${element.dataset.city} ${element.dataset.country}`;

    window.initMap = function() {
      // eslint-disable-next-line no-undef
      geoCoder = new google.maps.Geocoder();
      // eslint-disable-next-line no-undef
      map = new google.maps.Map(element, options);

      if (geoCoder) {
        geoCoder.geocode( { 'address': address}, function(results, status) {
          // eslint-disable-next-line no-undef
          if (status === google.maps.GeocoderStatus.OK && status !== google.maps.GeocoderStatus.ZERO_RESULTS) {
            map.setCenter(results[0].geometry.location);
            // eslint-disable-next-line no-undef
            new google.maps.Marker({
              position: results[0].geometry.location,
              map: map,
            });
          } else {
            // eslint-disable-next-line no-undef
            new google.maps.Marker({
              position: options.center,
              map: map,
            });
          }
          element.style.setProperty('display', 'block');
        });
      } else {
        element.style.setProperty('display', 'block');
      }
    };

    document.head.appendChild(script);
  }
}
