export default class Accordion {
  constructor( element ) {
    element.classList.add('javascript');

    element.addEventListener('click', () => {
      const item = document.querySelector('#' + element.getAttribute('aria-controls'));
      if ('true' === element.getAttribute('aria-expanded')) {
        element.setAttribute('aria-expanded', 'false');
        item.setAttribute('hidden', '');
      } else {
        element.setAttribute('aria-expanded', 'true');
        item.removeAttribute('hidden');
      }
    });
  }
}
