export default class MainMenu {
  constructor( element ) {
    element.classList.add('javascript');

    //mobile menu
    const menu = element.querySelector('.site-header__navigation');
    const mobileButton = element.querySelector('.site-header__navigation-toggle');

    const showMenu = function (/*event*/) {
      mobileButton.setAttribute('aria-expanded', 'true');
      menu.style.setProperty('display', 'block');

      document.body.style.setProperty('overflow', 'hidden');
      document.body.addEventListener('click', onClickOutside);
    };

    const hideMenu = function (/*event*/) {
      mobileButton.setAttribute('aria-expanded', 'false');
      menu.style.setProperty('display', 'none');

      document.body.style.removeProperty('overflow');
      document.body.removeEventListener('click', onClickOutside);
    };

    const onClickOutside = function (event) {
      if ('true' === mobileButton.getAttribute('aria-expanded') && !event.target.closest('.site-header')) {
        hideMenu();
      }
    };

    mobileButton.addEventListener('click', (/*event*/) => {
      if ('true' === mobileButton.getAttribute('aria-expanded')) {
        hideMenu();
      } else {
        showMenu();
      }
    });

    const submenuOpeners = element.querySelectorAll('.submenu-mobile__opener');

    for (const submenuOpener of submenuOpeners) {
      submenuOpener.addEventListener('click', function(event) {
        const item = element.querySelector('#' + event.target.getAttribute('aria-controls'));
        if ('true' === event.target.getAttribute('aria-expanded')) {
          item.style.setProperty('display', 'none');
          event.target.setAttribute('aria-expanded', 'false');
        } else {
          item.style.setProperty('display', 'block');
          event.target.setAttribute('aria-expanded', 'true');
        }
      });
    }

    window.addEventListener('resize', (/*event*/) => {
      if (window.innerWidth >= 992) {
        menu.style.removeProperty('display');
        mobileButton.setAttribute('aria-expanded', 'false');

        document.body.style.removeProperty('overflow');

        for (const submenuOpener of submenuOpeners) {
          submenuOpener.setAttribute('aria-expanded', 'false');
          element.querySelector('#' + submenuOpener.getAttribute('aria-controls')).style.setProperty('display', 'none');
        }
      }
    });
  }
}
