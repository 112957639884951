export default class InteractiveSvg {
  constructor(element) {
    element.classList.add('javascript');

    document.querySelectorAll('.interactive-svg-trigger').forEach((trigger) => {
      trigger.addEventListener('mouseover', (e) => {
        const allDescriptions = document.querySelectorAll(
          '.interactive-svg-description'
        );
        if (allDescriptions) {
          allDescriptions.forEach((description) => {
            description.classList.remove('visible');
          });
        }
        const hoverTargetId = trigger.dataset.hovertarget;
        const hoverTargetElement = document.querySelector(`#${hoverTargetId}`);
        if (hoverTargetElement) {
          hoverTargetElement.classList.add('visible');
          hoverTargetElement.style.setProperty(
            'top',
            `${e.pageY - hoverTargetElement.offsetHeight / 2}px`
          );
          const leftValue = Math.min(
            Math.max(e.pageX - hoverTargetElement.offsetWidth / 2, 10),
            document.documentElement.clientWidth -
              hoverTargetElement.offsetWidth -
              10
          );
          hoverTargetElement.style.setProperty('left', `${leftValue}px`);
        }
      });
    });

    document
      .querySelectorAll('.interactive-svg-description')
      .forEach((description) => {
        description.addEventListener('mouseover', (e) => {
          e.target.classList.add('visible');
        });
        description.addEventListener('mouseleave', (e) => {
          e.target.classList.remove('visible');
        });
      });

    document
      .querySelectorAll('.interactive-svg-description-close-button')
      .forEach((button) => {
        button.addEventListener('click', () => {
          const closeTargetId = button.dataset.closetarget;
          const closeTargetElement = document.querySelector(
            `#${closeTargetId}`
          );
          if (closeTargetElement) {
            closeTargetElement.classList.remove('visible');
          }
        });
      });

    const intersectionObserver = new IntersectionObserver(intersectionCallback);

    function intersectionCallback(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animated');
        }
      });
    }

    intersectionObserver.observe(element);
  }
}
