export default class Header {
  constructor( element ) {
    element.classList.add('javascript');

    let prevScrollpos = window.pageYOffset;

    document.addEventListener('scroll', function() {
      let currentScrollPos = window.pageYOffset;
      
      if (currentScrollPos > prevScrollpos) {
        element.classList.add('top');
      } else {
        element.classList.remove('top');
      }
      prevScrollpos = currentScrollPos;
    });
  }
}
