import { CountUp } from 'countup.js';

export default class CountElement{
  constructor (element){
    element.classList.add('javascript');

    const options = {
      separator: '.',
      decimal: ',',
      duration: 5
    };

    const endNumber = element.innerHTML;

    function isElementInViewport (el) {

      var rect = el.getBoundingClientRect();

      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    function onVisibilityChange(el, callback) {
      var old_visible;
      return function () {
        var visible = isElementInViewport(el);
        if (visible != old_visible) {
          old_visible = visible;
          if (typeof callback == 'function') {
            callback();
          }
        }
      };
    }

    var handler = onVisibilityChange(document.querySelector('.offerschart-picture-4'), function() {
      let counter = new CountUp(element, endNumber, options);
      counter.start();
    });

    if (window.addEventListener) {
      addEventListener('DOMContentLoaded', handler, false);
      addEventListener('load', handler, false);
      addEventListener('scroll', handler, false);
      addEventListener('resize', handler, false);
    }
  }
}


