import Init from '../../js/init';

export default class References {
  constructor( element ) {
    element.classList.add('javascript');

    element.querySelectorAll('.highlights').forEach(highlights => {
      highlights.querySelectorAll('.references-link').forEach(a => {
        a.addEventListener('click', (e) => {
          e.preventDefault();

          let xmlhttp = new XMLHttpRequest();
          xmlhttp.onreadystatechange = () => {
            if (xmlhttp.readyState === XMLHttpRequest.DONE) {
              if (xmlhttp.status === 200) {
                let parser = new DOMParser();
                let response = parser.parseFromString(xmlhttp.responseText, 'text/html');

                document.getElementById('references-menu').innerHTML = response.getElementById('references-menu').innerHTML;
                document.getElementById('references-list').innerHTML = response.getElementById('references-list').innerHTML;

                const init = new Init();
                init.initJSComponents(document);
              }
            }
          };

          xmlhttp.open('GET', a.href);
          xmlhttp.send();
        });
      });
    });
  }
}
