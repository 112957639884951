export default class Sticky {
  constructor( element ) {
    element.classList.add('javascript');

    const threshold = 50;
    const height = element.getBoundingClientRect().height;
    const top = element.previousElementSibling.offsetHeight + element.previousElementSibling.offsetTop;

    let previousPageYOffset = window.pageYOffset;
    let distance = 0;

    element.style.position = 'sticky';

    window.addEventListener('scroll', () => {
      const pageYOffset = window.pageYOffset;

      let currentDistance = +distance + +(pageYOffset - previousPageYOffset);

      if (Math.abs(currentDistance) > threshold) {
        distance = 0;

        if (pageYOffset > previousPageYOffset && pageYOffset > (height + top)) {
          element.classList.add('hide-sticky');
        } else {
          element.classList.remove('hide-sticky');
        }
      } else {
        distance = +currentDistance;
      }

      previousPageYOffset = pageYOffset;
    });
  }
}
