import SimpleLightbox from 'simplelightbox';

export default class ImageLightBox{
  constructor (element){
    element.classList.add('javascript');

    let lightbox1 = new SimpleLightbox('.image-lightbox1 a');
    let lightbox2 = new SimpleLightbox('.image-lightbox2 a');
    let lightbox3 = new SimpleLightbox('.image-lightbox3 a');
    let lightbox4 = new SimpleLightbox('.image-lightbox4 a');
    let lightbox5 = new SimpleLightbox('.image-lightbox5 a');
    lightbox1.next();
    lightbox2.next();
    lightbox3.next();
    lightbox4.next();
    lightbox5.next();
  }
}