import Swiper, { Navigation, Pagination, Autoplay, Parallax} from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay, Parallax]);
// import './slider.scss';

export default class Slider {
  constructor( element ) {
    element.classList.add('javascript');

    let next = element.querySelector('.slider-next');
    let prev = element.querySelector('.slider-prev');

    let options = {};

    if (element.classList.contains('team')) {
      next = element.parentNode.querySelector('.slider-next');
      prev = element.parentNode.querySelector('.slider-prev');

      options = {
        watchOverflow: true,
        slidesPerView: 1,
        // loop: true,
        navigation: {
          nextEl: next,
          prevEl: prev,
        },
        breakpoints: {
          1120: {
            slidesPerView: 2,
            spaceBetween: 25,
          },
          1400: {
            slidesPerView: 2,
            spaceBetween: 50,
          },
        },
      };
    }

    if (element.classList.contains('summary')) {
      options = {
        slidesPerView: 1,
        loop: true,
        navigation: {
          nextEl: next,
          prevEl: prev,
        },
      };
    }

    if (element.classList.contains('references')) {
      next = element.parentNode.querySelector('.slider-next');
      prev = element.parentNode.querySelector('.slider-prev');

      options = {
        watchOverflow: true,
        slidesPerView: 1,
        navigation: {
          nextEl: next,
          prevEl: prev,
        },
        breakpoints: {
          800: {
            slidesPerView: 2,
            spaceBetween: 25,
          },
          1240: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        },
      };
    }

    if (element.classList.contains('performance')) {
      next = element.parentNode.querySelector('.slider-next');
      prev = element.parentNode.querySelector('.slider-prev');

      options = {
        slidesPerView: 1,
        loop: true,
        navigation: {
          nextEl: next,
          prevEl: prev,
        },
      };
    }

    if (element.classList.contains('service') || element.classList.contains('slider')) {
      next = element.parentNode.querySelector('.slider-next');
      prev = element.parentNode.querySelector('.slider-prev');
      const pagination = element.parentNode.parentNode.querySelector('.slider-pagination');
      if (element.classList.contains('slider-autoplay') && element.classList.contains('slider-progressbar')) {
        options = {
          slidesPerView: 1,
          loop: true,
          navigation: {
            nextEl: next,
            prevEl: prev,
          },
          pagination: {
            el: pagination,
            clickable: true,
            bulletClass: 'highlights__item',
            bulletActiveClass: 'active',
            renderBullet: function (index, className) {
              return '<li class="'+className+'"><a class="highlights__link"><span>'+this.slides[index+1].dataset.label+'</span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" width="15" height="15"><path fill="none" stroke="white" d="M3,1L7,5L3,9"></path></svg></a></li>';
            },
          },
          parallax: {
            enabled: true,
          },
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          speed: 1000,
          on: {
            slideChangeTransitionStart: function () {
              document.getElementById('progress').classList.remove('swiper-progress-active');

            },
            slideChangeTransitionEnd: function () {
              document.getElementById('progress').classList.add('swiper-progress-active');

            },
          },
        };
      } else if (element.classList.contains('slider-autoplay')) {
        options = {
          slidesPerView: 1,
          loop: true,
          navigation: {
            nextEl: next,
            prevEl: prev,
          },
          pagination: {
            el: pagination,
            clickable: true,
            bulletClass: 'highlights__item',
            bulletActiveClass: 'active',
            renderBullet: function (index, className) {
              return '<li class="'+className+'"><a class="highlights__link"><span>'+this.slides[index+1].dataset.label+'</span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" width="15" height="15"><path fill="none" stroke="white" d="M3,1L7,5L3,9"></path></svg></a></li>';
            },
          },
          parallax: {
            enabled: true,
          },
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
        };
      } else {
        options = {
          slidesPerView: 1,
          loop: true,
          navigation: {
            nextEl: next,
            prevEl: prev,
          },
          pagination: {
            el: pagination,
            clickable: true,
            bulletClass: 'highlights__item',
            bulletActiveClass: 'active',
            renderBullet: function (index, className) {
              return '<li class="'+className+'"><a class="highlights__link"><span>'+this.slides[index+1].dataset.label+'</span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" width="15" height="15"><path fill="none" stroke="white" d="M3,1L7,5L3,9"></path></svg></a></li>';
            },
          },
          parallax: {
            enabled: true,
          },
        };
      }

    }

    if (element.classList.contains('testimonials')) {
      next = element.parentNode.querySelector('.slider-next');
      prev = element.parentNode.querySelector('.slider-prev');

      options = {
        watchOverflow: true,
        slidesPerView: 1,
        // loop: true,
        navigation: {
          nextEl: next,
          prevEl: prev,
        },
      };
    }

    if (element.classList.contains('images')) {
      next = element.parentNode.querySelector('.slider-next');
      prev = element.parentNode.querySelector('.slider-prev');

      options = {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 32,

        navigation: {
          nextEl: next,
          prevEl: prev,
        },
      };
    }

    if (element.classList.contains('news')) {
      next = element.parentNode.querySelector('.slider-next');
      prev = element.parentNode.querySelector('.slider-prev');

      options = {
        loop: true,
        slidesPerView: 1,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        parallax: {
          enabled: true,
        },
        speed: 1000,
        watchSlidesProgress: true,
        on: {
          slideChangeTransitionStart: function () {
            document.getElementById('progress').classList.remove('swiper-progress-active');

          },
          slideChangeTransitionEnd: function () {
            document.getElementById('progress').classList.add('swiper-progress-active');

          },
        },


        navigation: {
          nextEl: next,
          prevEl: prev,
        },
      };

    }

    if (element.classList.contains('news-minimal')) {
      next = element.parentNode.querySelector('.slider-next');
      prev = element.parentNode.querySelector('.slider-prev');

      options = {
        watchOverflow: true,
        loop: true,
        slidesPerView: 1,
        navigation: {
          nextEl: next,
          prevEl: prev,
        },
        breakpoints: {
          800: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 50,
          },
          1240: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 50,
          },
        },
      };

    }

    if (element.classList.contains('home-slider')) {
      options = {
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
      };
    }

    if (element.classList.contains('home-slider-no-autoplay')) {
      options = {
        slidesPerView: 1,
      };
    }
    
    if (element.classList.contains('references-big')) {
      next = element.parentNode.querySelector('.slider-next-round');
      let mobile = false;

      if (window.screen.width <= 688) {
        mobile = true;
      }

      options = {
        loop: true,
        slidesPerView: 1,
        parallax: {
          enabled: true,
        },
        autoplay: mobile,

        navigation: {
          nextEl: next,
        },
      };

    }

    if (element.classList.contains('has-multi-columns')) {
      delete options.breakpoints;
    }

    if (!element.classList.contains('start')){
      new Swiper(element, options);
    } else if (element.classList.contains('start')) {
      /* const headerTextSwiper = */ new Swiper(element,
        {
          freeMode: true,
          autoHeight: true,
          slidesPerView: 1,
          loop: true,
          allowTouchMove: false,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          }
        });
    }
  }
}
