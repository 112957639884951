export default class Benefits {
  constructor( element ) {
    element.classList.add('javascript');

    const benefitIcons = element.querySelectorAll('.benefit-wrapper');
    const descriptions = document.querySelectorAll('.benefit-description');
    const titles = document.querySelectorAll('.benefit-title');

    benefitIcons.forEach((benefitIcon, index) => {
      benefitIcon.addEventListener('mouseover',() => {
        descriptions[index].classList.add('show');
        titles[index].classList.add('show');
      });
    });

    for (const benefitIcon of benefitIcons) {
      benefitIcon.addEventListener('mouseout', () => {
        descriptions.forEach((description) => description.classList.remove('show'));
        titles.forEach((title) => title.classList.remove('show'));
      });
    }
  }
}