export default class VisibilityToggle {
  constructor( element ) {
    element.classList.add('javascript');

    const triggers = element.getElementsByClassName('visibility-toggle-trigger');

    for (let trigger of triggers) {
      trigger.addEventListener('click', () => {
        const item = document.querySelector('#' + trigger.getAttribute('aria-controls'));
        if ('true' === trigger.getAttribute('aria-expanded')) {
          trigger.classList.remove('active');
          trigger.setAttribute('aria-expanded', 'false');
          if (null !== item) {
            item.setAttribute('hidden', '');
          }
        } else {
          element.querySelectorAll('.active').forEach((trigger) => {
            const item = document.querySelector('#' + trigger.getAttribute('aria-controls'));
            trigger.classList.remove('active');
            trigger.setAttribute('aria-expanded', 'false');
            if (null !== item) {
              item.setAttribute('hidden', '');
            }
          });
          trigger.classList.add('active');
          trigger.setAttribute('aria-expanded', 'true');
          if (null !== item) {
            item.removeAttribute('hidden');
          }
        }
      });
    }
  }
}
