/**
 * Main JavaScript file
 *
 * This is the main JavaScript file that loads all the modules and initializes them on DOM ready.
 */
import Init from '../js/init';

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (let i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}


document.addEventListener('DOMContentLoaded', function () {
  console.debug('loading js components');

  const init = new Init;
  init.initJSComponents(document);
}, false);
