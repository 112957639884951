import AOS from 'aos';
import 'aos/dist/aos.css';

export default class AnimateElement{
  constructor (element){
    element.classList.add('javascript');

    AOS.init({
      delay: 50,  
    });

    AOS.refresh();

    window.addEventListener('load', function() {
      AOS.refresh();
    });
  }
}
