export default class LocationMap {
  constructor(element) {
    element.classList.add('javascript');

    document.querySelectorAll('.location-marker').forEach((marker) => {
      marker.addEventListener('mouseover', (e) => {
        const allLocations = document.querySelectorAll('.location-description');
        if (allLocations) {
          allLocations.forEach((location) => {
            location.classList.remove('visible');
          });
        }
        const locationId = e.target.dataset.location;
        const locationElement = document.querySelector(`#${locationId}`);
        if (locationElement) {
          locationElement.classList.add('visible');
          const container = document.querySelector('.location-map-container');
          if (container) {
            locationElement.style.setProperty(
              'top',
              `${e.pageY - locationElement.offsetHeight}px`
            );
            locationElement.style.setProperty(
              'left',
              `${e.pageX - locationElement.offsetWidth / 2}px`
            );
          }
        }
      });
    });

    document
      .querySelectorAll('.location-description')
      .forEach((locationDescription) => {
        locationDescription.addEventListener('mouseover', (e) => {
          e.target.classList.add('visible');
        });
        locationDescription.addEventListener('mouseleave', (e) => {
          e.target.classList.remove('visible');
        });
      });
  }
}
