export default class Filter {
  constructor( element ) {
    element.classList.add('javascript');

    element.querySelectorAll('.filter.active').forEach(filter =>
      element.querySelectorAll('.filter-item.' + filter.dataset.department).forEach(item => item.removeAttribute('hidden'))
    );

    const filters = element.querySelectorAll('.filter');

    for (const filter of filters) {
      filter.addEventListener('click', () => {
        element.querySelectorAll('.active').forEach((filter) => filter.classList.toggle('active'));
        filter.classList.toggle('active');

        element.querySelectorAll('.filter-item.' + filter.dataset.department).forEach(item =>
          item.removeAttribute('hidden')
        );
        element.querySelectorAll('.filter-item:not(.' + filter.dataset.department + ')').forEach(item =>
          item.setAttribute('hidden', '')
        );

        //update query
        const query = new URLSearchParams(window.location.search);
        query.set('department', filter.dataset.department);
        window.history.pushState(null, null, '?' + query.toString());
      });
    }
  }
}
