import SelectPure from 'select-pure';
// import './slider.scss';

export default class JobSearch {
  constructor( element ) {
    element.classList.add('javascript');

    const multiSelects = element.getElementsByClassName('multi-select');
    const selects = {};

    const url = element.dataset.url;

    for (let multiSelect of multiSelects) {
      const options = JSON.parse(multiSelect.dataset.options);
      const multiple = JSON.parse(multiSelect.dataset.multiple || true);

      let value;
      if (multiple) {
        value = options.filter(option => option.selected).map(option => option.value);
      } else {
        value = options.find(option => option.selected);
        if (value) {
          value = value.value;
        } else {
          value = null;
        }
      }


      selects[multiSelect.dataset.name] = new SelectPure(multiSelect, {
        options: options,
        value: value,
        multiple: multiple,
        placeholder: multiSelect.dataset.label,
        onChange: loadData,
      });

      const clearIcon = document.createElement('i');
      clearIcon.classList.add('clear');
      clearIcon.addEventListener('click', () => {
        selects[multiSelect.dataset.name].reset();
        loadData();
      });
      multiSelect.appendChild(clearIcon);

    }

    const submits = element.getElementsByClassName('submit');

    for (let submit of submits) {
      submit.addEventListener('click', (e) => {
        if (submit.href) {
          e.preventDefault();

          window.location.href = submit.href + '?' + getQuery().toString();
        } else {
          window.history.pushState(null, null, '?' + getQuery().toString());
        }
      });
    }

    function getQuery() {
      const query = new URLSearchParams();

      for (let [key, values] of Object.entries(selects)) {
        let selectedValues = values._config.multiple ? values.value() : values.value() ? [values.value()] : [];

        for (let value of selectedValues) {
          query.append(values._config.multiple ? key + '[]' : key, value);
        }
      }

      return query;
    }

    function loadData() {
      if (!url) {
        return;
      }

      const xmlHttp = new XMLHttpRequest();
      xmlHttp.onreadystatechange = () => {
        if (xmlHttp.readyState === XMLHttpRequest.DONE) {
          if (xmlHttp.status === 200) {
            const parser = new DOMParser();
            const response = parser.parseFromString(xmlHttp.responseText, 'text/html');

            document.getElementById('job-search-results').innerHTML = response.getElementById('job-search-results').innerHTML;

            window.history.pushState(null, null, '?' + getQuery().toString());
          }
        }
      };

      xmlHttp.open('GET', url + '?' + getQuery().toString());
      xmlHttp.send();
    }
  }
}
